import React, { useState } from 'react';
import { css } from '@emotion/react';
import Link from './link';
import ConditionalWrapper from './conditional-wrapper';
import { Detail } from './icons'; 

export default function PeopleListItem({ className, url, title, position, location }) {
  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => (
        <Link className={`${className} type--underline-inverse`} to={url}>
          {children}
        </Link>
      )}
    >
      <div
        className="type--heading-five"
        css={css`
          display: flex;
          align-items: center;
          padding: 3rem 0 2.4rem;
          border-top: 3px solid var(--black);
        `}
      >
        {title}, {position}
        {url && 
          <Detail 
            css={css`
              margin-left: auto;
            `}
          />
        }
      </div>
    </ConditionalWrapper>
  );
}
