import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

export default function TextInputBar({
  className,
  onChange,
  placeholder,
}) {

  return (
    <div
      className={className}
    >
      <div
        className="type--heading-five"
        css={css`
          display: flex;
          margin-bottom: var(--margin3);
        `}
      >
        <input
          type="search"
          name="search"
          placeholder={placeholder ? placeholder : 'Search by name'}
          css={css`
            flex-grow: 1;
            line-height: 1;
            padding-bottom: 2.8rem !important;
            border: none;
            border-bottom: 3px solid !important;
            border-radius: 0;
            border-color: var(--black) !important;

            @media (max-width: 550px) {
              padding-bottom: 2rem !important;
            }

            @media (max-width: 350px) {
              overflow-x: hidden;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
              display: none;
            }
          `}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
