import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function ViewButton({ className, items, active, itemOnClick }) {
  const [showItemsCard, setShowItemsCard] = useState(false);

  return (
    <div
      className={`type--mono-heading ${className}`}
      css={css`
        position: relative;
        z-index: 2;
      `}
    >
      <div>
        <span>View: </span>
        <span
          css={css`
            text-decoration: underline;
            cursor: pointer;
          `}
          onClick={() => setShowItemsCard(!showItemsCard)}
        >
          {items[active]}
        </span>
      </div>
      <div
        css={css`
          position: absolute;
          top: calc(100% + var(--gutterHalf));
          left: calc(var(--gutterHalf) * -1);
          display: ${showItemsCard ? 'block' : 'none'};
          width: 23rem;
          color: var(--white);
          text-align: left;
          background-color: var(--black);
          padding: var(--gutterHalf);
        `}
      >
        {items &&
          items.map((item, i) => {
            return (
              <div
                key={i}
                css={css`
                  text-decoration: ${i === active ? 'underline' : 'none'};
                  cursor: pointer;

                  &:hover {
                    text-decoration: underline;
                  }
                `}
                onClick={() => {
                  itemOnClick(i);
                  setShowItemsCard(!showItemsCard);
                }}
              >
                {item}
              </div>
            );
          })}
      </div>
    </div>
  );
}
